import "../../assets/scss/holo-leap/privacy-policy.scss";

function HoloLeap_TOC() {
  return (
    <>
      <div class="page">
        <div class="header">
          <div class="container">
            <p class="title p-0">Terms of Service for Holo Leap</p>
          </div>
        </div>
        <div class="translations-content-container">
          <div class="container">
            <div
              class="tab-content translations-content-item en visible"
              id="en"
            >
              <h1>Terms of Service</h1> <p>
                By downloading or using the game, these terms will
                automatically apply to you – you should make sure therefore
                that you read them carefully before using the game. You’re
                not allowed to copy, or modify the game, any part of the game,
                or our trademarks in any way. You’re not allowed to attempt
                to extract the source code of the game, and you also
                shouldn’t try to translate the game into other languages, or
                make derivative versions. The game itself, and all the trade
                marks, copyright, database rights and other intellectual
                property rights related to it, still belong to
                Autopilot Studio Sdn Bhd.
              </p> <p>
                The Holo Leap game stores and processes personal data
                that you have provided to us, in order to provide
                our Service. It’s your responsibility to keep your
                phone and access to the game secure. We therefore recommend
                that you do not jailbreak or root your phone, which is the
                process of removing software restrictions and limitations
                imposed by the official operating system of your device. It
                could make your phone vulnerable to
                malware/viruses/malicious programs, compromise your phone’s
                security features and it could mean that the
                Holo Leap game won’t work properly or at all.
              </p> <p>
                You should be aware that there are certain things that
                Autopilot Studio Sdn Bhd will not take responsibility for.
                Certain functions of the game will require the game to have an
                active internet connection. The connection can be Wi-Fi, or
                provided by your mobile network provider, but
                Autopilot Studio Sdn Bhd cannot take responsibility for the
                game not working at full functionality if you don’t have
                access to Wi-Fi, and you don’t have any of your data
                allowance left.
              </p> <p></p> <p>
                If you’re using the game outside of an area with Wi-Fi, you
                should remember that your terms of the agreement with your
                mobile network provider will still apply. As a result, you
                may be charged by your mobile provider for the cost of data
                for the duration of the connection while accessing the game,
                or other third party charges. In using the game, you’re
                accepting responsibility for any such charges, including
                roaming data charges if you use the game outside of your home
                territory (i.e. region or country) without turning off data
                roaming. If you are not the bill payer for the device on
                which you’re using the game, please be aware that we assume
                that you have received permission from the bill payer for
                using the game.
              </p> <p>
                Along the same lines, Autopilot Studio Sdn Bhd cannot always
                take responsibility for the way you use the game i.e. You
                need to make sure that your device stays charged – if it
                runs out of battery and you can’t turn it on to avail the
                Service, Autopilot Studio Sdn Bhd cannot accept
                responsibility.
              </p> <p>
                With respect to Autopilot Studio Sdn Bhd’s responsibility for
                your use of the game, when you’re using the game, it’s
                important to bear in mind that although we endeavour to
                ensure that it is updated and correct at all times, we do
                rely on third parties to provide information to us so that
                we can make it available to you.
                Autopilot Studio Sdn Bhd accepts no liability for any loss,
                direct or indirect, you experience as a result of relying
                wholly on this functionality of the game.
              </p> <p>
                At some point, we may wish to update the game. The game is
                currently available on Android &amp; iOS – the requirements for
                both systems (and for any additional systems we
                decide to extend the availability of the game to) may change,
                and you’ll need to download the updates if you want to keep
                playing the game. Autopilot Studio Sdn Bhd does not promise that
                it will always update the game so that it is relevant to you
                and/or works with the Android &amp; iOS version that you have
                installed on your device. However, you promise to always
                accept updates to the game when offered to you, We
                may also wish to stop providing the game, and may terminate
                use of it at any time without giving notice of termination
                to you. Unless we tell you otherwise, upon any termination,
                (a) the rights and licenses granted to you in these terms
                will end; (b) you must stop using the game, and (if needed)
                delete it from your device.
              </p> <p><h2>Changes to This Terms of Service</h2></p> <p>
                We may update our Terms of Service
                from time to time. Thus, you are advised to review this page
                periodically for any changes. We will
                notify you of any changes by posting the new Terms and
                Conditions on this page. These changes are effective
                immediately after they are posted on this page.
              </p> <p><h2>Contact Us</h2></p> <p>
                If you have any questions or suggestions about
                our Terms of Service, do not hesitate to
                contact us at <></>
                <a
                  href="https://autopilotstudio.com/reach-out-to-us"
                  rel="external nofollow noopener"
                  target="_blank"
                >
                  https://autopilotstudio.com/reach-out-to-us
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HoloLeap_TOC;
