import React, { useRef, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { PageDirectory } from "directory";

import "../assets/scss/unity.scss";

import unity_bg from "../assets/img/unity/unity-bg.png";
import tv_desk from "../assets/img/unity/tv-desk.png";
import tv from "../assets/img/unity/background-element/TV.png";
import logo from "../assets/img/unity/loading-logo.png";
import loading_bg from "../assets/img/unity/loading-bg.png";

import { useMobileOrientation, isMobile, isSafari } from "react-device-detect";

function UnityPage() {
  const {
    unityProvider,
    loadingProgression,
    isLoaded,
    sendMessage,
    requestFullscreen,
    addEventListener,
    removeEventListener,
  } = useUnityContext({
    loaderUrl: "build/WebGL.loader.js",
    dataUrl: "build/WebGL.data.unityweb",
    frameworkUrl: "build/WebGL.framework.js.unityweb",
    codeUrl: "build/WebGL.wasm.unityweb",
  });

  const log = (message, ...optionalParams) => {
    let logging = false;
    if (logging) {
      console.log(message, ...optionalParams);
    }
  };

  // const handleSetScore = React.useCallback((score) => {
  //     log("SCORE", score);
  //     // alert(score);
  // }, []);

  const handleText = React.useCallback((text) => {
    log("Text get: ", text);
  }, []);

  const handleInt = React.useCallback((num) => {
    log("Int get: ", num);
    if (num >= 100 && num < 500) {
      // the code range of 100 and 499 is used as the code for redirect page
      // this code range is self pre-set and is pass from Unity Instance
      redirectToPage(num);
    }
  }, []);

  const redirectToPage = (num) => {
    // logic to disect the code to get understand the page to redirect
    //  ------- updated on 28 Aug 2023: -------
    // the object that interact and the code it passed from Unity
    //      Showcase (1**) //scene
    //          OldComputer (101)
    //          Cassette (102)
    //          CD (103)
    //          OldRadio (104)

    //      Articles (2**) //scene
    //          Research A (201)
    //          Research B (202)
    //          Research C (203)

    //      OurPurpose (3**) //scene
    //          MainControl (301)

    //      Contact Us (4**) //scene
    //          Globe (401)

    let page = window.location.origin + "/";
    const page_code = Math.floor(num / 100);
    // log(page_code);
    switch (page_code) {
      case 1:
        page += PageDirectory.SHOWCASE;
        break;
      case 2:
        page += PageDirectory.ARTICLES;
        break;
      case 3:
        page += PageDirectory.OUR_PURPOSE;
        break;
      case 4:
        page += PageDirectory.CONTACT_US;
        break;
      default:
        console.error("Unexpected Code Get!!\nCode: ", page_code);
        return;
    }
    // log(page);
    if (!isSafari) {
      window.open(page, "_blank");
    } else {
      setTimeout(() => {
        window.open(page, "_top");
      });
    }
  };

  const handleWindowBlurOnUnity = () => {
    log("Window lost focus");
    sendMessage("AudioMixerManager", "Mute");
    // Add any actions you want to perform when the window loses focus
  };

  const handleWindowFocusOnUnity = () => {
    log("Window gained focus");
    sendMessage("AudioMixerManager", "Unmute");
    // Add any actions you want to perform when the window gains focus
  };

  React.useEffect(() => {
    // listen on event from Unity
    addEventListener("UnityPassText", handleText);
    addEventListener("UnityPassInt", handleInt);
    return () => {
      removeEventListener("UnityPassText", handleText);
      removeEventListener("UnityPassInt", handleInt);
    };
  }, [addEventListener, removeEventListener, handleText, handleInt]);

  function handleClickEnterFullscreen() {
    log("Go Full Screen!!");
    requestFullscreen(true);
  }

  // loading text
  let loadingTextCounter = 0;
  const loadingTexts = [
    "Loading",
    "Preparing spaceship",
    "Unloading cargo",
    "Assembling robots",
    "Wearing suits",
    "Defending threats",
    "Tidying Room",
  ];

  const maxCount = 3;
  // const defaultLoadingText = "Loading";
  let counter = 0;
  const [loadingText, setLoadingText] = useState(
    loadingTexts[loadingTextCounter]
  );

  /**
   * function for loading anim (...)
   */
  const loadingTextAnim = () => {
    // log("counter: " + counter);
    if (counter >= maxCount) {
      counter = 0;
      // setCounter(0);
      setLoadingText(loadingTexts[loadingTextCounter]);
    } else {
      counter++;
      // setCounter((prevCounter) => prevCounter + 1);
      setLoadingText((preText) => preText + ".");
    }
  };

  const updateLoadingText = () => {
    loadingTextCounter++;
    if (loadingTextCounter >= loadingTexts.length) {
      loadingTextCounter = 0;
    }
    setLoadingText(loadingTexts[loadingTextCounter]);
  };

  React.useEffect(() => {
    let intervalId;
    let intervalId_1;
    if (!isLoaded) {
      intervalId = setInterval(() => {
        // update
        loadingTextAnim();
      }, 200);
      intervalId_1 = setInterval(() => {
        updateLoadingText();
      }, 2000);
    } else {
      clearInterval(intervalId);
      clearInterval(intervalId_1);
    }

    return () => {
      // log("clearInterval")
      clearInterval(intervalId);
      clearInterval(intervalId_1);
    };
  }, [isLoaded]);

  React.useEffect(() => {
    if (isLoaded) {
      // tell the unity apps that whether isMobile
      sendMessage("ResponsiveDetection", "SetIsMobile", isMobile ? 1 : 0);

      // when loaded, check whether window is focus, and called the respective logic for it
      if (document.hasFocus()) {
        handleWindowFocusOnUnity();
      } else {
        handleWindowBlurOnUnity();
      }

      // add event that called when window is focus or lost focus
      // the event must be added after unity isLoaded for the send message to work properly
      window.addEventListener("blur", handleWindowBlurOnUnity);
      window.addEventListener("focus", handleWindowFocusOnUnity);
    }

    return () => {
      window.removeEventListener("blur", handleWindowBlurOnUnity);
      window.removeEventListener("focus", handleWindowFocusOnUnity);
    };
  }, [isLoaded]);

  const [startLoadingProgress, setStartLoadingProgress] = useState(-1);
  // use startLoadingProgress so that the loading bar looks better
  // the loadingProgress will return 0.9 before start loading, this is kind of a unityInstance bug
  const getLoadingPrecentage = () => {
    // skip any logic and return 100 percent if it's already loaded
    if (isLoaded) return 100;

    // catch the value of loading progress when it is first updated
    // after save the value then skip this process
    if (startLoadingProgress <= 0) {
      // avoid get the min (0) and max (0.9) value
      if (loadingProgression > 0 && loadingProgression < 0.9) {
        setStartLoadingProgress(loadingProgression);
        log("init loading progress: ", startLoadingProgress);
      } else {
        return 0;
      }
    }

    if (!isLoaded && loadingProgression < 0.9) {
      return (
        ((loadingProgression - startLoadingProgress) /
          (0.9 - startLoadingProgress)) *
        100
      );
    } else {
      return 100;
    }
  };

  /**
   * for loading view
   */
  const loadingView = () => {
    return (
      <>
        <div
          className="position-relative"
          style={{ backgroundColor: "black", width: `100%`, height: "100%" }}
        >
          <div
            className="container d-flex align-items-center justify-content-center h-100 p-4"
            style={{ height: "100%" }}
          >
            <div className=" position-relative m-2 w-80vw">
              <div className="logo m-3 d-flex justify-content-center align-items-center">
                <img
                  src={logo}
                  className="img-fluid"
                  alt="Logo"
                  style={{
                    width: "350px",
                    height: "auto",
                  }}
                />
              </div>
              <div
                className="loading-bar-bg position-relative"
                // style={{ background: `url(${loading_bg})`, height: "38px", width: '100%', backgroundRepeat: "no-repeat", backgroundSize: "strecth" }}
                style={{
                  borderTop: "5px",
                  borderBottom: "5px",
                  borderLeft: "10px",
                  borderRight: "10px",
                  borderStyle: "solid",
                  // borderColor: "white",
                  borderImage: `url(${loading_bg}) 15% 3%`,
                  borderImageRepeat: "stretch",
                }}
              >
                <div className="loading-bar h-100">
                  <div
                    className="loading-bar-fill"
                    style={{
                      backgroundColor: "#00F3DF",
                      height: "18px",
                      // height: "100%",
                      // width: `50%`,
                      width: `${getLoadingPrecentage()}%`,
                      position: "relative",
                    }}
                  ></div>
                </div>
              </div>
              <div className="m-3">
                <div className="loading-text">
                  {loadingProgression < 0.9
                    ? // if still loading
                      loadingText
                    : // if 0.9 but is not loaded
                    // it's unity instance bug, it will show progress of 0.9 but actually unity instance is not loaded yet
                    !isLoaded
                    ? loadingText
                    : "All Clear to go"}
                </div>
                <div className="loading-progress m-2">
                  {`${getLoadingPrecentage().toFixed(2)}%`}
                </div>
                {/* <p style={{ color: "#777777" }}>
                                {
                                    "DebugText: " + window.innerHeight
                                }
                            </p> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  /**
   * just unity game view
   */
  const unityView = () => {
    return (
      <>
        {/* <div className="overflow-hidden "
                style={{ width: "100%", height: "100%" }}
            > */}
        <Unity
          style={{
            display: isLoaded ? "block" : "none",
            width: "100%",
            height: "100%",
          }}
          unityProvider={unityProvider}
        />
        {/* </div> */}
      </>
    );
  };

  /**
   * the game view block with loading bar logic
   * @param {bool} isIncludeGame Set it to false to NOT load Unity Instance, normally used it for development purpose
   */
  const gameView = (isIncludeGame = true) => {
    return (
      <>
        <div
          className="position-relative overflow-hidden"
          style={{ width: "100%", height: "100%" }}
        >
          {/* // if the unity intance is not loaded yet, show the loading view */}
          {!isLoaded && loadingView()}
          {isIncludeGame ? unityView() : null}
        </div>
      </>
    );
  };

  /**
   *  the layout that contain tv frame and background with the game
   */
  const desktopView = () => {
    return (
      <>
        <div
          className="overflow-hidden"
          style={{
            background: `url(${unity_bg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
          }}
        >
          <div
            className="overflow-hidden position-relative"
            style={{
              background: `url(${tv_desk})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgrounasdasddPosition: "bottom",
              height: "100%",
            }}
          >
            <div
              className="tv-border"
              style={{
                // left: '20%',
                // right: '20%',
                // top: '10%',
                // bottom: '10%',
                // transform: 'translate(-10%, -5%)',
                // border: "100px solid black",
                // marginLeft: "2vw",
                // marginRight: "2vw",
                borderStyle: "solid",
                borderTopWidth: "8vh",
                borderBottomWidth: "16vh",
                borderRightWidth: "8vh",
                borderLeftWidth: "8vh",
                borderImage: `url(${tv}) 9.9% 8% 20% 8% stretch`,
                position: "absolute",
                height: "90vh",
                aspectRatio: "1.77",
                width: "90vw * 1.77",
                bottom: "6%",
                left: "calc((100vw - 90vh * 1.77)/2)",
              }}
            >
              <div
                className="position-relative"
                style={{
                  height: "100%",
                  // transform: 'translate(-50%, -38%)',
                }}
              >
                <div
                  className="position-relative"
                  style={{
                    height: "100%",
                    width: "100%",
                    // transform: 'translate(10%, 10%)',
                    backgroundColor: "green",
                  }}
                >
                  {gameView()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const getWindowInnerHeight = (percent = 100) => {
    return Math.floor((window.innerHeight * percent) / 100);
  };

  const gamveViewHeightRatio = () => {
    return 100 - footerHeightRatio;
  };
  const footerHeightRatio = 4;
  const footerDesktopHeight = 28;

  return (
    <>
      <div
        className="overflow-hidden"
        style={{
          backgroundColor: "#555555",
          height: isMobile ? `${getWindowInnerHeight()}px` : `100%`,
          zIndex: 100,
          position: "sticky"
        }}
      >
        {/* <div>
            <button onClick={handleClickEnterFullscreen}>Enter Fullscreen</button>
            </div> */}
        <div
          className="overflow-hidden"
          style={{
            height: isMobile
              ? `${getWindowInnerHeight(gamveViewHeightRatio())}px`
              : `calc(100vh - ${footerDesktopHeight}px)`,
          }}
        >
          {gameView(true)}
        </div>
        <div
          className="d-flex align-items-center justify-content-center footnote-text py-2"
          style={{
            height: isMobile
              ? `${getWindowInnerHeight(footerHeightRatio)} px`
              : `${footerDesktopHeight}px`,
            fontSize: "0.9rem",
          }}
        >
          {"© 2023 Autopilot Studio. All rights reserved."}
        </div>
        {/* < a href="/index" target="_blank">
            <div className="websiteBtn text-white py-2 px-4 text-center" style={{ border: "3px solid #fff", position: "absolute", top: '3%', right: '3%', width: "fit-content", borderRadius: "25px", backgroundColor: "#090B24" }}>
                <span className="text-white text-center">Website</span>
            </div>
            </a> */}
        {
          // ** this is the Archive part of code
          // **this is the code that show for the television frame
          // isMobile ?
          //     <div className="w-100 h-100 overflow-hidden">
          //         {gameView()}
          //     </div>
          //     :
          //     desktopView()
        }
      </div>
    </>
  );
}

export default UnityPage;
