import React, { useEffect, useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const gameList = [
  { img: require("../assets/img/home/game1.webp").default, title: "Pixel Quest" },
  { img: require("../assets/img/home/game2.webp").default, title: "Mystic Mage Academy" },
  { img: require("../assets/img/home/game3.webp").default, title: "Fantasy Card Battles" },
  { img: require("../assets/img/home/game1.webp").default, title: "Pixel Quest" },
  { img: require("../assets/img/home/game2.webp").default, title: "Mystic Mage Academy" },
  { img: require("../assets/img/home/game3.webp").default, title: "Fantasy Card Battles" },
];

function Index() {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    lazyLoad: "ondemand",
    autoplay: true,
    initOnLoad: true,
    nextSlidesToPreload: 1,
    speed: 300,
    slidesToShow: window.innerWidth > 992 ? 3 : window.innerWidth > 677 ? 1 : 1,
    centerMode: true,
    afterChange: (current) => {
      setCurrentSlideIndex(current);
    },
  };

  const openFullScreen = (imgSrc) => {
    window.history.replaceState(null, "New Page Title", "/index/?game=" + currentSlideIndex.toString())

    document.getElementById('slider').style.transform = "scale(0)";
    // document.getElementById('section1Bg').style.marginTop = "-80vh";
    document.getElementById('section1Bg').style.backgroundSize = "100% 100%";
    document.getElementById('section1Bg').style.backgroundPosition = "bottom";
    document.getElementById('expandGameDiv').style.opacity = 1;
    document.getElementById('expandGameImg').style.top = 0;
    document.getElementById('expandGameImg').style.width = "100%";
    document.getElementById('expandGameImg').style.height = "60vh";
    document.getElementById('expandGameImg').style.objectFit = "cover";
    document.getElementById('expandGameImg').style.objectPosition = "100% 85%";
    document.getElementById('expandGameImg').style.borderRadius = window.innerWidth > 991 ? "0 0 160px 160px" : "0 0 60px 60px";

    setTimeout(() => {
      setIsFullScreen(true);
      document.getElementById('slider').style.display = "none";
      document.getElementById('gameDetails').style.paddingTop = "70vh";
      document.getElementById('gameDetails').style.opacity = 1;
    }, 1500);
  };

  const closeFullScreen = () => {
    setIsFullScreen(false);
  };

  React.useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const gameParam = queryParameters.get("game");

    if (gameParam) {
      openFullScreen();
    }
    else {
      window.history.replaceState(null, "New Page Title", "/index")
    }
  }, []);

  return (
    <>
      <IndexNavbar />

      <div className="vh-100 w-100 overflow-hidden position-fixed">
        <video autoPlay loop playsInline muted preload="auto" className="w-100 h-100" style={{ objectFit: "cover", objectPosition: "center" }}>
          <source type="video/mp4" src={window.location.origin + "/starBg.mp4"} />
          Your browser does not support HTML5 video.
        </video>
      </div>

      <div className="row w-100 section1 mx-0 justify-content-center align-items-center">
        <div className="col-12 section1Bg px-0" id="section1Bg">
          <div className="" id="slider">
            <Slider {...settings} arrows={false} className="py-5">
              {gameList.map((item, i) => (
                <div className="mx-auto gameBoy" key={i}>
                  <div className="mx-auto position-relative">
                    <img src={require("../assets/img/home/card.webp").default} alt="Card" className="position-relative mx-auto" />
                    <img src={item.img} alt="Game" className="gameImg-position" />
                    <div className="gameTitle-position text-center">
                      <span className="text-white game-title">{item.title}</span>
                    </div>

                  </div>
                  <div className="mt-5 text-center">
                    <div className="view-btn py-3 px-5" style={{ cursor: "pointer" }} onClick={() => openFullScreen(item.img)}>
                      <span className="text-white view-txt">View Project</span>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="w-100 h-100" id="expandGameDiv">
            <img src={gameList[currentSlideIndex].img} alt="Game" className="expandGameImg" id="expandGameImg" />
          </div>

          <div id="gameDetails">
            {
              isFullScreen ?
                <div className="row justify-content-center align-items-center mx-0" >
                  <div className="col-11 col-lg-10">
                    <div className="row justify-content-center align-items-start">
                      <div className="col-12 col-lg-4 mb-4">
                        <div className="sticky-div">
                          <hr className="line mb-4 d-none d-md-block" />
                          <div className="d-flex align-items-center">
                            <div className="small-square mr-4 mr-lg-5" />
                            <span className="text-white game-desc-title">Pixel Quest</span>
                          </div>

                          <div className="mt-3">
                            <span className="text-white game-desc">
                              A retro-style platformer game where the player controls a brave adventure on a quest to rescue a kidnapped princess.
                              The player must navigate through challenging levels, defeat enemies, collect power-ups, and solve puzzles to progress through the game.
                            </span>
                          </div>

                          <hr className="line d-block d-md-none mt-4" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-8 mt-2 d-none d-md-block mb-4">
                        <div className="row justify-content-center">
                          <div className="col-12 col-lg-10">
                            <div className="row ">
                              <div className="col-4 px-0">
                                <div className="card-game-spec py-4 border-left">
                                  <div className="ml-4">
                                    <div >
                                      <span className="card-game-spec-title">Client</span>
                                    </div>
                                    <div className="mt-2">
                                      <span className="card-game-spec-value">Flying Whale</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4 px-0">
                                <div className="card-game-spec py-4 noBorder">
                                  <div className="ml-4">
                                    <div>
                                      <span className="card-game-spec-title">Industry</span>
                                    </div>
                                    <div className="mt-2">
                                      <span className="card-game-spec-value">Game</span>
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className="col-4 px-0">
                                <div className="card-game-spec py-4 border-right">
                                  <div className="ml-4">
                                    <div>
                                      <span className="card-game-spec-title">Region</span>
                                    </div>
                                    <div className="mt-2">
                                      <span className="card-game-spec-value">Europe</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 px-0 mt-5">
                                <div className="card-game-spec border py-4">
                                  <div className="row">
                                    <div className="col-2">
                                      <div className="row h-100 align-items-center">
                                        <div className="col h-100 text-center">
                                          <span className="game-intro-title red rotate mt-2">TASK</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-10 px-0">
                                      <div className="mx-4">
                                        <span className="game-intro-value">A retro-style platformer game where the player controls a brave adventurer on a quest to rescue a kidnapped princess.
                                          The player must navigate through challenging levels,
                                          defeat enemies, collect power-ups, and solve puzzles to progress through the game.
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 px-0 mt-5">
                                <div className="card-game-spec border py-4">
                                  <div className="row">
                                    <div className="col-2">
                                      <div className="row h-100 align-items-center ">
                                        <div className="col h-100 text-center">
                                          <span className="game-intro-title green rotate mt-2">SOLUTIONS</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-10 px-0">
                                      <div className="mx-4">
                                        <span className="game-intro-value">
                                          We wanted to design and develop a games for several VR devices to demostrate
                                          abilities of Unity 3D Engine. This project was not being viewed as a commercial one.
                                          So we had to deal with a limited budget and yet ensure cool user experience. Fighting off
                                          waves of incoming enemies was just the right solution from the level design perspective
                                          We were able to create a grim fantansy environment without having to design a huge map with too
                                          many elements of the environment. Which allowed us to focus on quality talking into account budget
                                          restrictions.
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 px-0 mt-5 pb-5">
                                <div className="card-game-spec border py-4">
                                  <div className="row">
                                    <div className="col-2">
                                      <div className="row h-100 align-items-center">
                                        <div className="col h-100 text-center">
                                          <span className="game-intro-title blue rotate mt-2">RESULT</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-10 px-0">
                                      <div className="mx-4">
                                        <span className="game-intro-value">
                                          The game won an award at an immersive Gaming exhibition. When we have some free in-house resources, we will
                                          re-design the game to make it a commercial success.
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-8 mt-2 d-block d-md-none">
                        <div className="row justify-content-center">
                          <div className="col-11">
                            <div className="row ">
                              <div className="col-12 col-md-8 px-1">
                                <div className="card-game-spec py-2">
                                  <div className="ml-4">
                                    <span className="card-game-spec-title">Client</span>
                                    <span className="card-game-spec-value ml-2">Flying Whale</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-6 px-1">
                                <div className="card-game-spec py-2 mt-3">
                                  <div className="ml-4">
                                    <span className="card-game-spec-title">Industry</span>
                                    <span className="card-game-spec-value ml-2">Game</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-6 px-1">
                                <div className="card-game-spec py-2 mt-3">
                                  <div className="ml-4">

                                    <span className="card-game-spec-title">Region</span>
                                    <span className="card-game-spec-value ml-2">Europe</span>

                                  </div>
                                </div>
                              </div>
                              <div className="col-12 px-0 mt-5">
                                <div className="card-game-spec border py-4">
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="mx-4">
                                        <div className="pb-4">
                                          <span className="game-intro-title red">Task</span>
                                        </div>
                                        <span className="game-intro-value">A retro-style platformer game where the player controls a brave adventurer on a quest to rescue a kidnapped princess.
                                          The player must navigate through challenging levels,
                                          defeat enemies, collect power-ups, and solve puzzles to progress through the game.
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 px-0 mt-5">
                                <div className="card-game-spec border py-4">
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="mx-4">
                                        <div className="pb-4">
                                          <span className="game-intro-title green">SOLUTIONS</span>
                                        </div>
                                        <span className="game-intro-value">
                                          We wanted to design and develop a games for several VR devices to demostrate
                                          abilities of Unity 3D Engine. This project was not being viewed as a commercial one.
                                          So we had to deal with a limited budget and yet ensure cool user experience. Fighting off
                                          waves of incoming enemies was just the right solution from the level design perspective
                                          We were able to create a grim fantansy environment without having to design a huge map with too
                                          many elements of the environment. Which allowed us to focus on quality talking into account budget
                                          restrictions.
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 px-0 mt-5 pb-5">
                                <div className="card-game-spec border py-4">
                                  <div className="row">

                                    <div className="col-12">
                                      <div className="mx-4">
                                        <div className="pb-4">
                                          <span className="game-intro-title blue">RESULT</span>
                                        </div>
                                        <span className="game-intro-value">
                                          The game won an award at an immersive Gaming exhibition. When we have some free in-house resources, we will
                                          re-design the game to make it a commercial success.
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-10 px-0 mt-5 text-center pb-5">
                        <img src={require("../assets/img/home/Robot-gif.gif").default} alt="" className="mb-4" />
                        <span className="text-white d-block">2023 Autopilot Studio. All rights reserved.</span>
                      </div>
                    </div>
                  </div>
                </div>

                : <></>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
