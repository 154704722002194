import IndexNavbar from "components/Navbars/IndexNavbar";
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Row, Col } from "reactstrap";

import news1Img from "../assets/img/news/news1.webp";
import news2Img from "../assets/img/news/news2.webp";
import news3Img from "../assets/img/news/news3.webp";
import news4Img from "../assets/img/news/news4.webp";
import news5Img from "../assets/img/news/news5.webp";
import news6Img from "../assets/img/news/news6.webp";

function ArticlesDetails() {

    const newsArray = [
        { img: news6Img, date: "14 July 2023", title: "Consultation", desc: "As a leading firm, we prioritize delivering quality and value to our clients. Our team excels in idea validation and brainstorming, ensuring your concepts are on the right track." },
        { img: news1Img, date: "14 July 2023", title: "Consultation", desc: "As a leading firm, we prioritize delivering quality and value to our clients. Our team excels in idea validation and brainstorming, ensuring your concepts are on the right track." },
        { img: news3Img, date: "14 July 2023", title: "Consultation", desc: "As a leading firm, we prioritize delivering quality and value to our clients. Our team excels in idea validation and brainstorming, ensuring your concepts are on the right track." },
    ]

    return (
        <>
            <IndexNavbar />
            <div className="vh-100 w-100 overflow-hidden position-fixed">
                <video autoPlay loop playsInline muted preload="auto" className="w-100 h-100" style={{ objectFit: "cover", objectPosition: "center" }}>
                    <source type="video/mp4" src={window.location.origin + "/starBg.mp4"} />
                    Your browser does not support HTML5 video.
                </video>
            </div>
            
            <div className="news">
                <div className="header-banner position-relative">
                    <div className="">
                        <img src={require("../assets/img/news/news2.webp").default} alt="purpose-robot" className="mx-auto w-100 newsBanner"/>
                    </div>
                    <Row className="justify-content-center align-items-center header-banner-title mx-0">
                        <Col lg={8} md={10} sm={11} xs={11}>
                            <span className="">UNREAL ENGINE GAME OPTIMIZATION</span>
                        </Col>
                    </Row>
                </div>

                <div className="newsDetailsSection py-5">
                    <Row className="justify-content-center align-items-center mx-0">
                        <Col lg={9} md={10} sm={11} xs={11} className="">
                            <span className="stepDesc d-block mb-3">
                                In the realm of game development, optimizing performance is a critical aspect of delivering immersive and seamless experiences.
                            </span>
                            <span className="stepDesc d-block mb-3">
                                Unreal Engine, renowned for its robust capabilities, offers a suite of powerful tools specifically designed to enhance performance. To ensure smooth gameplay experiences, developers must implement effective optimization techniques across various aspects of their projects. In this article, Argentics explores the concept of Unreal Engine performance optimization, delves into the challenges these tools can solve, and highlights essential Unreal Engine tools that can significantly boost your game development process.
                            </span>

                            <div className="py-5">
                                <span className="contentTitle d-block mb-3">
                                    UNREAL ENGINE TOOLS THAT SOLVE CHALLENGES
                                </span>
                                <img src={require("../assets/img/news/newsDetailsImg1.webp").default} alt="purpose-robot" className="mx-auto w-100" />
                            </div>

                            <span className="stepDesc d-block mb-3">
                                Unreal Engine performance optimization refers to the process of fine-tuning games built on the Unreal Engine to achieve optimal performance across various hardware configurations.
                            </span>

                            <div className="py-3">
                                <span className="smallTitle d-block mb-3">
                                    Image Resolution Unreal
                                </span>
                                <span className="stepDesc d-block mb-3">
                                    Engine tools excel at addressing challenges related to image resolution. They offer advanced techniques for handling texture compression, mipmapping, and LOD systems. These tools empower developers to strike a balance between visual fidelity and performance, enabling games to run smoothly on a range of hardware configurations.
                                </span>
                            </div>

                            <div className="">
                                <span className="smallTitle d-block mb-3">
                                    Levels of Detail
                                </span>
                                <span className="stepDesc d-block mb-3">
                                    LODs are essential for managing the complexity of 3D models and optimizing performance. Unreal Engine provides robust LOD creation tools, allowing developers to generate simplified versions of assets at different distances. This ensures that high-polygon models are replaced with lower-polygon versions when viewed from a distance, reducing the strain on system resources without sacrificing visual quality.
                                </span>
                            </div>

                            <div className="text-center pt-5">
                                <img src={require("../assets/img/purpose/header-deco.webp").default} alt="" className="mx-auto" />
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="newsSection">
                    <Row className="justify-content-center align-items-center min-height-100 mx-0">
                        <Col lg={9} md={11} sm={11} xs={11} className="">
                            <Row className="justify-content-center align-items-center min-height-100">
                                {
                                    newsArray.map((item, i) => (
                                        <Col lg={4} md={6} sm={12} xs={12} className="text-left mb-5 pb-5">
                                            <div className="text-left px-4">
                                                <img src={item.img} alt="" className="newsImg w-100" />
                                                <div className="mt-3">
                                                    <span className="dateNewsText d-block mb-2">{item.date}</span>
                                                    <span className="stepTitle d-block mb-2">{item.title}</span>
                                                    <span className="stepDivider d-block mb-4">.............</span>
                                                    <span className="stepDesc d-block mb-2">{item.desc}</span>
                                                </div>
                                                <a href="/articles-details">
                                                    <div className="readMoreBtn mt-4 py-3">
                                                        <span className="">Read More</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div className="text-center pb-5" style={{ paddingTop: "10vh" }}>
                    <img src={require("../assets/img/home/Robot-gif.gif").default} alt="" className="mb-4 robotGifSize" />
                    <span className="text-white d-block">2023 Autopilot Studio. All rights reserved.</span>
                </div>
            </div >
        </>
    );
}

export default ArticlesDetails;
