import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [navBarDisplay, setNavBarDisplay] = React.useState(null);
  const location = useLocation();
  const history = useHistory()

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 30 ||
        document.body.scrollTop > 30
      ) {
        setNavbarColor("navbar-icon");
      } else if (
        document.documentElement.scrollTop < 30 ||
        document.body.scrollTop < 30
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  function openMenuTab() {
    document.getElementById("menuPage").style.transform = 'translateX(0%)';
    document.getElementById("menuPageComponent").style.opacity = 1;
  }

  function closeMenu() {
    document.getElementById("menuPage").style.transform = 'translateX(100%)';
    document.getElementById("menuPageComponent").style.opacity = 0;
  }

  function navBarLogoDisplay() {
    if (location.pathname === "/articles-details") {
      return (
        <a href="/articles">
          <img src={require("../../assets/img/news/backBtn.webp").default} alt="Logo" className="ml-4 backBtnSize" />
        </a>
      )
    }
    else if ((location.search).includes("?game=")) {
      return (
        <a href="/showcase">
          <img src={require("../../assets/img/news/backBtn.webp").default} alt="Logo" className="ml-4 backBtnSize" />
        </a>
      )
    }
    else {
      return (
        <a href="/showcase">
          <img src={require("../../assets/img/logo/logo.webp").default} alt="Logo" className="logo-width" />
        </a>
      )
    }
  }

  React.useEffect(() => {
    setNavBarDisplay(navBarLogoDisplay());
  }, [history]);

  return (
    <>
      <Navbar className={"fixed-top " + navbarColor} expand="lg">
        <div className="row mt-4 fixed-top">
          <div className="col-6 col-md-6 logo">
            {
              navBarDisplay
            }
          </div>
          <div className="col-6 col-md-6 text-right logo">
            <div className="position-relative text-right menuBtnBg" onClick={() => openMenuTab()}>
              <span className="menu-text text-white">Menu</span>
            </div>
          </div>
        </div>
      </Navbar>

      <div className="menu" id="menuPage">
        <div className="row justify-content-center align-items-center menuPageBg min-height-100" id="menuPageComponent" >
          <div className="col-12 col-md-8 col-lg-6 text-center">
            <span className="menuTitle">Menu</span>
            <div className="mt-4 mt-md-5">
              <a href="/showcase">
                <div className="position-relative menuRow">
                  <div className="menuBtnDiv mx-auto mb-3 mb-md-4" id="showcaseBtn">
                    <span className="menuBtnText">Showcase</span>
                  </div>
                  <div className="d-none d-lg-block">
                    <img src={require("../../assets/img/menu-arrow.webp").default} alt="arrow" className="mr-4 menuArrow arrowPush" />
                  </div>
                </div>
              </a>
              <a href="/purpose">
                <div className="position-relative menuRow">
                  <div className="menuBtnDiv mx-auto mb-3 mb-md-4" id="purposeBtn">
                    <span className="menuBtnText">Our Purpose</span>
                  </div>
                  <div className="d-none d-lg-block">
                    <img src={require("../../assets/img/menu-arrow.webp").default} alt="arrow" className="mr-4 menuArrow arrowPush" />
                  </div>
                </div>
              </a>
              <a href="/articles">
                <div className="position-relative menuRow">
                  <div className="menuBtnDiv mx-auto mb-3 mb-md-4" id="articlesBtn">
                    <span className="menuBtnText">Articles</span>
                  </div>
                  <div className="d-none d-lg-block">
                    <img src={require("../../assets/img/menu-arrow.webp").default} alt="arrow" className="mr-4 menuArrow arrowPush" />
                  </div>
                </div>
              </a>
              <a href="/reach-out-to-us">
                <div className="position-relative menuRow">
                  <div className="menuBtnDiv mx-auto mb-3 mb-md-4" id="reachUsBtn">
                    <span className="menuBtnText">Reach Out To Us</span>
                  </div>
                  <div className="d-none d-lg-block">
                    <img src={require("../../assets/img/menu-arrow.webp").default} alt="arrow" className="mr-4 menuArrow arrowPush" />
                  </div>
                </div>
              </a>
            </div>

            <div className="d-flex justify-content-center align-items-center mt-4">
              <a href="https://www.instagram.com/autopilotstudio23/" target="_blank">
                <img src={require("../../assets/img/instaIcon.webp").default} alt="insta" className="mx-3 socialMediaSize" />
              </a>
              <img src={require("../../assets/img/facebookIcon.webp").default} alt="fb" className="mx-3 socialMediaSize" />
            </div>

            <div className="mt-4 mt-md-5">
              <img src={require("../../assets/img/closeBtn.webp").default} alt="closeBtn" className="closeBtnSize" onClick={() => closeMenu()} />
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default IndexNavbar;
