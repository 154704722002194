import IndexNavbar from "components/Navbars/IndexNavbar";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Row, Col } from "reactstrap";
import { useOnLoadImages } from "./useOnLoadImages";

import news1Img from "../assets/img/news/news1.webp";
import news2Img from "../assets/img/news/news2.webp";
import news3Img from "../assets/img/news/news3.webp";
import news4Img from "../assets/img/news/news4.webp";
import news5Img from "../assets/img/news/news5.webp";
import news6Img from "../assets/img/news/news6.webp";

function Articles() {

    const newsArray = [
        { img: news1Img, href: "/articles-details", date: "14 July 2023", title: "Consultation", desc: "As a leading firm, we prioritize delivering quality and value to our clients. Our team excels in idea validation and brainstorming, ensuring your concepts are on the right track." },
        { img: news2Img, href: "/articles-details", date: "14 July 2023", title: "Consultation", desc: "As a leading firm, we prioritize delivering quality and value to our clients. Our team excels in idea validation and brainstorming, ensuring your concepts are on the right track." },
        { img: news3Img, href: "/articles-details", date: "14 July 2023", title: "Consultation", desc: "As a leading firm, we prioritize delivering quality and value to our clients. Our team excels in idea validation and brainstorming, ensuring your concepts are on the right track." },
        { img: news4Img, href: "/articles-details", date: "14 July 2023", title: "Consultation", desc: "As a leading firm, we prioritize delivering quality and value to our clients. Our team excels in idea validation and brainstorming, ensuring your concepts are on the right track." },
        { img: news5Img, href: "/articles-details", date: "14 July 2023", title: "Consultation", desc: "As a leading firm, we prioritize delivering quality and value to our clients. Our team excels in idea validation and brainstorming, ensuring your concepts are on the right track." },
        { img: news6Img, href: "/articles-details", date: "14 July 2023", title: "Consultation", desc: "As a leading firm, we prioritize delivering quality and value to our clients. Our team excels in idea validation and brainstorming, ensuring your concepts are on the right track." },
    ]

    const [loading, setLoading] = useState(true);
    const wrapperRef = useRef(null);
    const imagesLoaded = useOnLoadImages(wrapperRef);

    const handleLoading = () => {
        setLoading(false);
    }

    useEffect(() => {
        window.addEventListener("load", handleLoading);
        return () => window.removeEventListener("load", handleLoading);
    }, [])

    return (
        <>
            <div ref={wrapperRef}>
                <IndexNavbar />
                <div className="vh-100 w-100 overflow-hidden position-fixed">
                    <video autoPlay loop playsInline muted preload="auto" className="w-100 h-100" style={{ objectFit: "cover", objectPosition: "center" }}>
                        <source type="video/mp4" src={window.location.origin + "/starBg.mp4"} />
                        Your browser does not support HTML5 video.
                    </video>
                </div>

                <div className="news w-100">
                    <div className="header-section pt-5 pt-lg-0">
                        <Row className="justify-content-center align-items-center mx-0 pt-5 pt-lg-0 w-100">
                            <Col lg={5} md={8} sm={10} xs={10} className="text-center mb-4">
                                <img src={require("../assets/img/home/news.gif").default} alt="purpose-robot" className="mx-auto newsGif" />
                            </Col>
                            <Col lg={6} md={11} sm={11} xs={11}>
                                <div className="header-title-section">
                                    <div className="small-square mr-4 mr-lg-5" />
                                    <div className="header-title-text">
                                        <span>Articles</span>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <span className="header-small-title d-block mb-4">
                                        Explore a vast world of the game industry through our articles.
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="text-center">
                        <img src={require("../assets/img/purpose/header-deco.webp").default} alt="" className="mx-auto decoSize" />
                    </div>

                    <div className="newsSection mt-5 py-5">
                        <Row className="justify-content-center align-items-center mx-0 w-100">
                            <Col lg={9} md={11} sm={12} xs={12} className="">
                                <Row className="justify-content-center align-items-center w-100 mx-auto">
                                    {/* {
                                    newsArray.map((item, i) => (
                                        <Col lg={4} md={6} sm={12} xs={12} className="text-left mb-5 pb-5">
                                            <div className="text-left px-4">
                                                <img src={item.img} alt="" className="newsImg w-100" />
                                                <div className="mt-3">
                                                    <span className="dateNewsText d-block mb-2">{item.date}</span>
                                                    <span className="stepTitle d-block mb-2">{item.title}</span>
                                                    <span className="stepDivider d-block mb-4">.............</span>
                                                    <span className="stepDesc d-block mb-2">{item.desc}</span>
                                                </div>
                                                <a href={item.href}>
                                                    <div className="readMoreBtn mt-4 py-3">
                                                        <span className="">Read More</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </Col>
                                    ))
                                } */}
                                    <Col lg={10} md={10} sm={12} xs={12}>
                                        <img src={require("../assets/img/news/comingSoon.webp").default} alt="" className="mx-auto w-100" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    <div className="text-center pb-5" style={{ paddingTop: "10vh" }}>
                        <img src={require("../assets/img/home/Robot-gif.gif").default} alt="" className="mb-4 robotGifSize" />
                        <span className="text-white d-block copywriteText">2023 Autopilot Studio. All rights reserved.</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Articles;
