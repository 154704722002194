import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Index from "views/Index.js";

import "assets/scss/public.scss";
import "assets/scss/custom.scss";
import "assets/scss/menu.scss";
import "assets/scss/purpose.scss";
import "assets/scss/news.scss";
import "assets/scss/contactUs.scss";

import OurPurpose from "views/OurPurpose";
import Articles from "views/Articles";
import ArticlesDetails from "views/ArticlesDetails";
import ReachUs from "views/ReachUs";
import UnityPage from "views/UnityPage";
import HoloLeap_PrivacyPolicy from "views/holo-leap/PrivacyPolicy"
import HoloLeap_TOS from "views/holo-leap/TermOfService"

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        {/* <Route path="/index" render={(props) => <Index {...props} />} /> */}
        <Route path="/purpose" render={(props) => <OurPurpose {...props} />} />
        <Route path="/articles" render={(props) => <Articles {...props} />} />
        <Route path="/articles-details" render={(props) => <ArticlesDetails {...props} />} />
        <Route path="/reach-out-to-us" render={(props) => <ReachUs {...props} />} />
        <Route path="/showcase" render={(props) => <UnityPage {...props} />} />

        {/* Holo Leap's directory */}
        <Route path="/holo-leap/privacy" render={(props) => <HoloLeap_PrivacyPolicy {...props} />} />
        <Route path="/holo-leap/tos" render={(props) => <HoloLeap_TOS {...props} />} />
        <Redirect from="/holo-leap/toc" to="/holo-leap/tos" />

        <Redirect to="/showcase" />
        <Redirect from="/" to="/showcase" />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
